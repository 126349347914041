import CohortFormPromptItem from '@cohort/merchants/apps/cohort-form/components/CohortFormPromptItem';
import CohortFormPromptSheet from '@cohort/merchants/apps/cohort-form/components/CohortFormPromptSheet';
import Button from '@cohort/merchants/components/buttons/Button';
import DraggableList from '@cohort/merchants/components/form/DraggableList';
import LocalizedTextEditorInput from '@cohort/merchants/components/form/textEditor/LocalizedTextEditorInput';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {getDefinedLanguages} from '@cohort/merchants/lib/form/localization';
import type {CohortFormPrompt} from '@cohort/shared/schema/common/cohortForm';
import type {CohortFormConfig as CohortFormConfigType} from '@cohort/shared/schema/common/cohortForm';
import {CohortFormConfigSchema} from '@cohort/shared/schema/common/cohortForm';
import {PlusCircle} from '@phosphor-icons/react';
import {Fragment, useEffect, useRef, useState} from 'react';
import type {
  ArrayPath,
  Control,
  FieldArray,
  FieldValues,
  Path,
  UseFormRegister,
} from 'react-hook-form';
import {useController, useFieldArray, useWatch} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type FormModalState = {
  open: boolean;
  prompt?: CohortFormPrompt;
  promptIdx?: number;
};

type CohortFormConfigProps<T extends FieldValues> = {
  register: UseFormRegister<T>;
  control: Control<T>;
  fieldPaths: {
    prompts: Path<T>;
    description: Path<T>;
    selectedLanguage?: Path<T>;
    definedLanguages?: Path<T>;
  };
  existingConfig?: CohortFormConfigType | null;
};

const CohortFormConfig = <T extends FieldValues>({
  register,
  control,
  fieldPaths,
  existingConfig,
}: CohortFormConfigProps<T>): React.ReactNode => {
  const merchant = useCurrentMerchant();
  const {t} = useTranslation('components', {keyPrefix: 'form.cohortForm.cohortFormConfig'});
  const {field: definedLanguagesField} = useController({
    control,
    name: fieldPaths.definedLanguages ?? ('definedLanguages' as Path<T>),
  });
  const {fieldState: promptsFieldState} = useController({control, name: fieldPaths.prompts});
  const errorRef = useRef<HTMLDivElement>(null);

  const [formModal, setFormModal] = useState<FormModalState>({
    open: false,
    prompt: undefined,
    promptIdx: undefined,
  });

  function closeModal(): void {
    setFormModal({open: false, prompt: undefined});
  }

  const {fields, append, remove, update, replace} = useFieldArray({
    keyName: '_id',
    name: fieldPaths.prompts as ArrayPath<T>,
    control,
  });

  const selectedLanguage = useWatch({
    control,
    name: fieldPaths.selectedLanguage ?? ('selectedLanguage' as Path<T>),
  });

  useEffect(() => {
    if (promptsFieldState.error && errorRef.current) {
      errorRef.current.scrollIntoView();
    }
  }, [promptsFieldState.error]);

  // update defined languages regarding the initial form configuration
  useEffect(() => {
    const config = CohortFormConfigSchema.safeParse(existingConfig);
    const prompts = config.success ? config.data.prompts : null;
    const description = config.success ? config.data.description : {};
    const promptsNames = [...(prompts ?? []).map(prompt => prompt.name)];
    const promptsOptionsLabels = [
      ...(prompts ?? []).map(prompt => (prompt.options ?? []).map(option => option.label)),
    ].flat();

    // used a set to remove duplications
    const newDefinedLanguages = new Set([
      ...definedLanguagesField.value,
      ...getDefinedLanguages(merchant.defaultLanguage, [
        description,
        ...promptsNames,
        ...promptsOptionsLabels,
      ]),
    ]);

    definedLanguagesField.onChange(Array.from(newDefinedLanguages));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDefaultLanguageSelected = selectedLanguage === merchant.defaultLanguage;

  return (
    <Fragment>
      <LocalizedTextEditorInput
        name={fieldPaths.description}
        label={t('label')}
        placeholder={t('placeholder')}
        register={register}
        control={control}
        selectedLanguage={selectedLanguage}
      />
      <div className="flex w-full flex-col">
        <label className="mb-1 block text-sm font-medium text-slate-700">{t('titlePrompts')}</label>
        {fields.length === 0 && (
          <div
            ref={errorRef}
            className="rounded-md border-2 border-dashed py-2 text-center text-sm font-medium text-slate-500"
          >
            {t('placeholderNoPrompt')}
          </div>
        )}
        <DraggableList
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          handleOnReorder={ids => replace(ids.map(id => fields.find(field => field._id === id)!))}
          items={fields.map((q, index) => {
            // the fields items are typed as FieldArrayWithId<T, ArrayPath<T>, "id"> and are not directly assignable to CohortFormPrompt
            const prompt = q as unknown as CohortFormPrompt & {_id: string};
            return {
              item: (
                <CohortFormPromptItem
                  key={prompt.id}
                  prompt={prompt}
                  handleEdit={() => {
                    setFormModal({
                      open: true,
                      prompt,
                      promptIdx: index,
                    });
                  }}
                  handleRemove={isDefaultLanguageSelected ? () => remove(index) : undefined}
                  selectedLanguage={selectedLanguage}
                />
              ),
              id: prompt._id,
            };
          })}
        />
        {promptsFieldState.error && (
          <p className="text-sm text-red-500" data-testid="integration.config.prompts-error">
            {t('errorEmpty')}
          </p>
        )}
        {isDefaultLanguageSelected && (
          <Button
            onClick={() => setFormModal({open: true, prompt: undefined})}
            data-testid="add-prompt"
            variant="ghost"
            className="mt-1 w-fit"
          >
            <PlusCircle className="-ml-1 mr-2 h-5 w-5" />
            {t('buttonNewPrompt')}
          </Button>
        )}
      </div>
      {formModal.open && (
        <CohortFormPromptSheet
          title={formModal.prompt ? t('updatePromptTitle') : t('addPromptTitle')}
          prompt={formModal.prompt}
          onClose={closeModal}
          onSave={prompt => {
            if (formModal.promptIdx !== undefined) {
              update(formModal.promptIdx, prompt as FieldArray<T, ArrayPath<T>>);
            } else {
              append(prompt as FieldArray<T, ArrayPath<T>>);
            }
            closeModal();
          }}
          selectedLanguage={selectedLanguage}
          existingConfig={existingConfig}
        />
      )}
    </Fragment>
  );
};

export default CohortFormConfig;
