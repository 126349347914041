import {cn} from '@cohort/shared-frontend/utils/classNames';
import React from 'react';

const Card = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({className, style, ...props}, ref) => (
    <div
      ref={ref}
      className={cn(
        'relative overflow-hidden border bg-[--xps-card-background-color] p-2',
        className
      )}
      style={{
        borderRadius: 'var(--xps-card-border-radius)',
        borderColor: 'var(--xps-card-border-color)',
        ...style,
      }}
      {...props}
    />
  )
);

const CardTitle = React.forwardRef<HTMLHeadingElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({...props}, ref) => <h2 ref={ref} {...props} />
);

const CardTitleSecondary = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({...props}, ref) => <h3 ref={ref} {...props} />);

const CardText = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({...props}, ref) => <p ref={ref} {...props} />
);

const CardFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({children, ...props}, ref) => (
    <div
      ref={ref}
      {...props}
      className="flex flex-col border-t border-[--xps-border-color] pt-4 text-sm text-[--xps-card-footer-color]"
    >
      {children}
    </div>
  )
);

export {Card, CardTitle, CardTitleSecondary, CardText, CardFooter};
