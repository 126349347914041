import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import CohortFormConfig from '@cohort/merchants/apps/cohort-form/components/CohortFormConfig';
import {CohortFormConfigSchema} from '@cohort/shared/schema/common/cohortForm';

const CohortFillFormTriggerIntegrationConfigComponent: React.FC<
  TriggerIntegrationConfigComponentProps
> = ({formReturn: {register, control}, trigger}) => {
  const parsedConfig = CohortFormConfigSchema.optional().safeParse(
    trigger?.triggerIntegrationConfig
  );
  const existingConfig = parsedConfig.success ? parsedConfig.data : null;

  return (
    <CohortFormConfig
      register={register}
      control={control}
      fieldPaths={{
        prompts: 'triggerIntegrationConfig.prompts',
        description: 'triggerIntegrationConfig.description',
      }}
      existingConfig={existingConfig}
    />
  );
};

export default CohortFillFormTriggerIntegrationConfigComponent;
