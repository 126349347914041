import ScoreView from '@cohort/merchants/apps/cohort-form/perkIntegrations/fillForm/ScoreView';
import type {CohortFormPromptType} from '@cohort/shared/schema/common/cohortForm';
import {formatDate} from '@cohort/shared/utils/format';

export const formatCohortFormAnswer = (
  questionType: CohortFormPromptType,
  response: string | string[] | number | boolean | Date | null | undefined
): string | JSX.Element => {
  if (
    response === '' ||
    response === undefined ||
    response === null ||
    (Array.isArray(response) && response.length === 0)
  ) {
    return '-';
  }
  if (questionType === 'date') {
    return formatDate(new Date(response.toString()));
  }
  if (questionType === 'score') {
    return <ScoreView score={Number(response)} />;
  }
  return response.toString();
};
