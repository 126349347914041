import type {UserEventADto} from '@cohort/admin-schemas/userEvents';
import {cn} from '@cohort/shared-frontend/utils/classNames';

export const formatDetailComponentBoldText = (
  userEvent: UserEventADto,
  navigateFn?: () => void
): JSX.Element => {
  return (
    <span
      className={cn('font-semibold', !userEvent.isResourceDeleted && 'cursor-pointer text-primary')}
      onClick={() => !userEvent.isResourceDeleted && navigateFn?.()}
    />
  );
};
