// i18nOwl-ignore [app-cohort-form.description]
import type {App, TriggerIntegration} from '@cohort/merchants/apps';
import translationEn from '@cohort/merchants/apps/cohort/locales/en.json';
import translationFr from '@cohort/merchants/apps/cohort-form/locales/fr.json';
import {CohortFormPerkIntegration} from '@cohort/merchants/apps/cohort-form/perkIntegrations/fillForm/PerkIntegration';
import {CohortFillFormTriggerIntegration} from '@cohort/merchants/apps/cohort-form/triggerIntegrations/fillForm/TriggerIntegration';
import type {CohortFormAppStruct} from '@cohort/shared/apps/cohort-form';
import {CohortFormAppSpec} from '@cohort/shared/apps/cohort-form';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';

const CohortFormApp: App<CohortFormAppStruct> = {
  spec: CohortFormAppSpec,
  ConnectionEditComponent: null,
  locales: {
    en: translationEn,
    fr: translationFr,
  },
  logo: <SvgAppIcon name="cohort-form" height={40} width={40} />,
  medias: [],
  notificationIntegrations: [],
  perkIntegrations: [CohortFormPerkIntegration],
  triggerIntegrations: [CohortFillFormTriggerIntegration] as Array<TriggerIntegration>,
  userEvents: [],
};

export default CohortFormApp;
