import type {PerkUsageViewComponentProps} from '@cohort/merchants/apps';
import {formatCohortFormAnswer} from '@cohort/merchants/apps/cohort-form/utils/format';
import {PerkUsageLayout} from '@cohort/merchants/components/perks/PerkUsageLayout';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {CohortFormUsageData} from '@cohort/shared/apps/cohort-form/perks/fillForm';
import type {CohortFormConfig, CohortFormPrompt} from '@cohort/shared/schema/common/cohortForm';

const CohortFormPerkIntegrationsUsageViewComponent: React.FC<PerkUsageViewComponentProps> = ({
  usage,
}) => {
  const merchant = useCurrentMerchant();
  const config = usage.integrationConfig as CohortFormConfig;

  const renderPrompt = (question: CohortFormPrompt): JSX.Element => {
    const data = usage.data as CohortFormUsageData;
    const name = question.name[merchant.defaultLanguage] ?? '';
    const response = formatCohortFormAnswer(question.type, data.values[question.id]);
    return <PerkUsageLayout.Row name={name} value={response} key={question.id} />;
  };

  return (
    <PerkUsageLayout.Grid>
      {config.prompts.map(prompt => renderPrompt(prompt))}
    </PerkUsageLayout.Grid>
  );
};

export default CohortFormPerkIntegrationsUsageViewComponent;
