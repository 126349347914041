import type {Language, LocalizedRichText, LocalizedString} from '@cohort/shared/schema/common';
import {LanguageSchema} from '@cohort/shared/schema/common';
import type {LocalizedFaqs} from '@cohort/shared/schema/common/campaign';
import type {CohortFormOption, CohortFormPrompt} from '@cohort/shared/schema/common/cohortForm';
import type {FieldValues} from 'react-hook-form';
import {pick} from 'remeda';
import {z} from 'zod';

export const LocalizedFormSchema = z.object({
  defaultLanguage: LanguageSchema,
  selectedLanguage: LanguageSchema,
  definedLanguages: z.array(LanguageSchema),
});
type LocalizedFormValues = z.infer<typeof LocalizedFormSchema>;
export type LocalizedFieldValues<T extends FieldValues = FieldValues> = T & LocalizedFormValues;

export function getDefinedLanguages(
  defaultLanguage: Language,
  localizedContentList?: Array<LocalizedString | LocalizedFaqs | LocalizedRichText>
): Array<Language> {
  const definedLanguages = new Array<Language>(defaultLanguage);
  if (localizedContentList === undefined) {
    return definedLanguages;
  }
  for (const localizedContent of localizedContentList) {
    for (const language in localizedContent) {
      if (
        !definedLanguages.includes(language as Language) &&
        localizedContent[language as Language] !== null
      ) {
        definedLanguages.push(language as Language);
      }
    }
  }
  return definedLanguages;
}

export function removeUndefinedLanguages<T extends LocalizedString | LocalizedFaqs | undefined>(
  localizedContent: T,
  definedLanguages?: Array<Language>
): T {
  if (localizedContent === undefined || definedLanguages === undefined) {
    return localizedContent;
  }

  return pick(localizedContent, definedLanguages) as T;
}

export const sortLanguages = (
  languages: Array<Language>,
  getLanguageTranslation: (language: Language) => string,
  defaultLanguage?: Language
): Array<Language> => {
  return [
    ...languages.sort((a: Language, b: Language) => {
      if (a === defaultLanguage) {
        return -1;
      }
      if (b === defaultLanguage) {
        return 1;
      }
      return getLanguageTranslation(a).localeCompare(getLanguageTranslation(b));
    }),
  ];
};

export const removeLanguagesFromPrompts = (
  prompts: Array<CohortFormPrompt>,
  definedLanguages?: Array<Language>
): Array<CohortFormPrompt> => {
  if (definedLanguages === undefined) {
    return prompts;
  }
  return prompts.map(prompt => {
    const options: Array<CohortFormOption> | null =
      prompt.options?.map(option => {
        return {
          ...option,
          label: removeUndefinedLanguages(option.label, definedLanguages),
        };
      }) ?? null;
    const name = removeUndefinedLanguages(prompt.name, definedLanguages);
    return {
      ...prompt,
      name,
      options,
    };
  });
};
