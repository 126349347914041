import CohortFormPromptTypeLabel from '@cohort/merchants/apps/cohort-form/components/CohortFormPromptTypeLabel';
import DraggableListItem from '@cohort/merchants/components/form/DraggableListItem';
import {ValueNotTranslatedLabel} from '@cohort/merchants/components/ValueNotTranslatedLabel';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import type {Language} from '@cohort/shared/schema/common';
import type {CohortFormPrompt} from '@cohort/shared/schema/common/cohortForm';

const CohortFormPromptItem: React.FC<{
  prompt: CohortFormPrompt;
  handleEdit: () => void;
  handleRemove?: () => void;
  selectedLanguage: Language;
}> = ({prompt, handleEdit, handleRemove, selectedLanguage}) => {
  const merchant = useCurrentMerchant();

  return (
    <DraggableListItem onEdit={handleEdit} onRemove={handleRemove}>
      <div className="flex items-center justify-between gap-2">
        {prompt.name[selectedLanguage] !== undefined ? (
          <span className="flex items-center text-sm">{prompt.name[selectedLanguage]}</span>
        ) : (
          <ValueNotTranslatedLabel
            defaultValue={prompt.name[merchant.defaultLanguage]}
            language={selectedLanguage}
          />
        )}
        <span className="font-regular whitespace-nowrap rounded-md bg-slate-100 px-2 py-1 text-sm leading-4 text-slate-600">
          <CohortFormPromptTypeLabel type={prompt.type} />
        </span>
      </div>
    </DraggableListItem>
  );
};
export default CohortFormPromptItem;
