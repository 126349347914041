import type {PerkIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import CohortFormConfig from '@cohort/merchants/apps/cohort-form/components/CohortFormConfig';
import {useCurrentPerk} from '@cohort/merchants/hooks/contexts/currentPerk';
import {useCohortForm} from '@cohort/merchants/hooks/contexts/form';
import type {PerkFormValues} from '@cohort/merchants/pages/perks/perk/edit/utils';
import {CohortFormConfigSchema} from '@cohort/shared/schema/common/cohortForm';

const CohortFormPerkIntegrationConfigComponent: React.FC<
  PerkIntegrationConfigComponentProps
> = () => {
  const perk = useCurrentPerk();
  const {register, control} = useCohortForm<PerkFormValues>();
  const parsedConfig = CohortFormConfigSchema.optional().safeParse(perk.integration?.config);
  const existingConfig = parsedConfig.success ? parsedConfig.data : null;

  return (
    <CohortFormConfig
      register={register}
      control={control}
      fieldPaths={{
        prompts: 'integration.config.prompts',
        description: 'integration.config.description',
      }}
      existingConfig={existingConfig}
    />
  );
};

export default CohortFormPerkIntegrationConfigComponent;
