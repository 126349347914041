import {
  LanguageSchema,
  LocalizedRichTextSchema,
  LocalizedStringSchema,
} from '@cohort/shared/schema/common';
import {z} from 'zod';

export const CohortFormPromptTypeSchema = z.enum([
  'checkbox',
  'date',
  'email',
  'long-text',
  'number',
  'text',
  'select',
  'score',
]);
export type CohortFormPromptType = z.infer<typeof CohortFormPromptTypeSchema>;

export const CohortFormOptionSchema = z.object({
  value: z.string().min(1),
  label: z.record(LanguageSchema, z.string().min(1).or(z.null())),
});
export type CohortFormOption = z.infer<typeof CohortFormOptionSchema>;

export const CohortFormPromptSchema = z
  .object({
    id: z.string().uuid(),
    name: LocalizedStringSchema,
    referenceId: z.string().min(1).nullable(),
    type: CohortFormPromptTypeSchema,
    options: z.array(CohortFormOptionSchema).nullable(),
    multipleChoice: z.boolean().nullable(),
    mandatory: z.boolean(),
    userPropertyId: z.string().uuid().nullable(),
  })
  .transform(data => {
    if (Array.isArray(data.options) && data.options.length === 0) {
      data.options = null;
    }
    return data;
  })
  .superRefine(
    (
      request: {type: CohortFormPromptType; options: Array<CohortFormOption> | null},
      ctx: z.RefinementCtx
    ) => {
      if (request.type !== 'select' && request.options !== null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'options must be null if type is not select',
          path: ['options'],
        });
      }
      if (request.type === 'select' && request.options === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'options must be an array if type is select',
          path: ['options'],
        });
      }
    }
  );
export type CohortFormPrompt = z.infer<typeof CohortFormPromptSchema>;

export const CohortFormConfigSchema = z.object({
  description: LocalizedRichTextSchema,
  prompts: z.array(CohortFormPromptSchema).min(1, {message: 'errorRequired'}),
});
export type CohortFormConfig = z.infer<typeof CohortFormConfigSchema>;

export const CohortFormAnswerSchema = z.union([
  z.boolean(),
  z.number(),
  z.date(),
  z.string(),
  z.null(),
  z.array(z.string()),
]);

export type CohortFormAnswer = z.infer<typeof CohortFormAnswerSchema>;
