// i18nOwl-ignore [app-cohort-form.perkIntegrations.fill-form.title, app-cohort-form.perkIntegrations.fill-form.description]
import type {PerkIntegration} from '@cohort/merchants/apps';
import CohortFormPerkIntegrationConfigComponent from '@cohort/merchants/apps/cohort-form/perkIntegrations/fillForm/ConfigComponent';
import CohortFormPerkIntegrationsUsageViewComponent from '@cohort/merchants/apps/cohort-form/perkIntegrations/fillForm/UsageViewComponent';
import type {CohortFormPerkIntegrationStruct} from '@cohort/shared/apps/cohort-form/perks/fillForm';
import {CohortFormPerkIntegrationSpec} from '@cohort/shared/apps/cohort-form/perks/fillForm';

export const CohortFormPerkIntegration: PerkIntegration<CohortFormPerkIntegrationStruct> = {
  spec: CohortFormPerkIntegrationSpec,
  configComponent: CohortFormPerkIntegrationConfigComponent,
  usageViewComponent: CohortFormPerkIntegrationsUsageViewComponent,
  iconColor: 'text-primary',
  backgroundColor: 'bg-primary/10',
};
